import React from "react";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <>
            <Button
              variant="primary"
              onClick={handleShow}
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite /> &nbsp;
              {"Demo Video"}
            </Button>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <video width="100%" height="400" controls>
                  <source src={props.demoLink} type="video/mp4" />
                  Tu navegador no soporta la reproducción de video.
                </video>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
