import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/nespresso.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/cura-deuda-website.png";
import chatify from "../../Assets/Projects/camsal.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/cura-deuda-webapp.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Camsal"
              description="Project oriented to the booking of apartments in Mexico, we integrated a booking engine called amenitiz. It was developed in React and Typescript and integrated with material UI and a small design system for the styles."
              demoLink="https://paovarizo.com/camsalvideo.mov"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="CuraDeuda App"
              description="Web app for Cura Deuda oriented to the online contracting of services. It was developed with React, Javascript and NextJs. For the states we use Redux. "
              demoLink="https://paovarizo.com/curadeudawebappvideo.mov"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="CuraDeuda Website"
              description="Website for Cura Deuda oriented to show the company information as well as the login and registration forms. "
              demoLink="https://paovarizo.com/curadeudawebsitevideo.mov"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Nesspreso"
              description="Web app for Nespresso in F1 collaboration. A site where users were directed to register the codes that came in the boxes and answer a trivia quiz to win F1 tickets."
              demoLink="https://paovarizo.com/nespressovideo.mov"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
